/* *FOOTER* */
#rinrose_footer {
  position: sticky;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 2;

  #rinrose_footer-contact_items {
    a {
      color: #fff;
      text-decoration: underline;

      &:hover {
        color: #b3c6d4;
        text-decoration: none;
      }
    }
  }

  #rinrose_footer-social {
    .btn {
      width: 36px;
      height: 36px;
      border-width: 2px;
    }
  }

  .rinrose_footer-icons_icon {
    img {
      max-height: 32px;
      width: auto;
      max-width: 100%;
    }
  }

  #rinrose_footer-links {
    a:hover {
      color: #b3c6d4 !important;
    }
  }

  #rinrose_footer-logo {
    #skylight {
      display: block;
      transition: all 1s;

      &:hover {
        opacity: 0.5;
      }
    }

    img {
      max-height: 37px;
      width: auto;
      max-width: 100%;
    }
  }

  #rinrose_footer-legal > {
    .list-group-item {
      &:last-child {
        width: 100% !important;
        padding-right: 0 !important;
        padding-left: 0 !important;
      }

      &:hover {
        color: #b3c6d4 !important;
      }
    }
  }

  #rinrose_footer-tenderling {
    a:hover {
      text-decoration: underline !important;
    }
  }
}

.form-modal-dialog {
  .btn-close {
    width: 40px;
    height: 40px;
    background-size: 40px;
  }

  .modal-header {
    border-bottom-width: 0;
  }

  .wpcf7-form {
    input {
      max-width: 100%;
      background-color: $primary;
      color: $white;
      box-shadow: none;
      border: 0 solid $white;
      border-bottom-width: 1px;
      font-weight: 400;
      letter-spacing: 0.05em;

      &::placeholder {
        color: $white;
        font-weight: 600;
      }
    }

    .wpcf7-response-output {
      color: $white;
      border-width: 0;
      text-align: left;
      padding: 0;
    }

    &.sent {
      & > div:not(.wpcf7-response-output) {
        display: none;
      }
    }
  }
}
