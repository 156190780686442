/* *HOME* */
.template-home {
  &.no-scroll-true {
    #rinrose_header {
      background-color: transparent !important;
    }

    #rinrose_banner {
      display: none !important;
    }
  }

  #rinrose_header-main_brand {
    opacity: 0 !important;
  }

  &:not(.no-scroll-true) {
    #rinrose_header-main_brand {
      opacity: 1 !important;
    }
  }

  .wrapper {
    & > #content {
      position: relative;

      &::after {
        content: "";
        width: 100%;
        height: $headerHeight;
        background-color: $white;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 2;
      }
    }
  }

  &.admin-bar {
    .wrapper {
      & > #content {
        &::after {
          height: $headerHeightAdmin;
        }
      }
    }
  }
}

#rinrose_home-splash {
  overflow: hidden;
  max-height: calc(100vh - $headerHeight);
  height: calc(100vh - $headerHeight);

  @media (orientation: portrait) {
    max-height: 56vw;
    height: 56vw;
  }

  .admin-bar & {
    height: calc(100vh - $headerHeightAdmin);
    max-height: calc(100vh - $headerHeightAdmin);

    @media (orientation: portrait) {
      max-height: 56vw;
      height: 56vw;
    }
  }

  #rinrose_home-splash_mask {
    transition: all 0s linear;

    &.splash-exit-triggerred {
      transition: all 2s ease-in-out;
    }
  }

  #rinrose_home-splash_mask-logo {
    position: absolute;
    padding-top: $headerHeight;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: all 0.2s ease-in-out;

    &.splash-exit-triggerred {
      transition: all 2s ease-in-out;
    }

    .admin-bar & {
      padding-top: $headerHeightAdmin;
    }

    img {
      margin-left: $rinroseRmargin;
      width: $rinroseRratio;
      max-width: $rinroseRratio;
      margin-right: auto;
      max-height: 92%;

      @media (orientation: portrait) {
        margin-left: 0;
        transform: translateY(-10%);
      }
    }
  }

  #rinrose_home-splash_video {
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    transition: all 0.2s ease-in-out;

    &.splash-exit-triggerred {
      transition: all 2s ease-in-out;
    }

    @media (orientation: portrait) {
      min-height: 56vw;
      height: 100%;
      margin-top: 0;
    }

    .admin-bar & {
      @media (orientation: portrait) {
        min-height: calc(56vw + $mobileAdminBarHeight);
      }
    }
  }

  #rinrose_home-splash_activate {
    &.splash-active {
      position: fixed;
      top: 0;
      left: 0;
      padding-top: $headerHeight;
      width: 100%;
      max-height: 100vh;
      height: 100vh;
      z-index: 3;

      @media (orientation: portrait) {
        max-height: calc(56vw + $headerHeight);
      }

      .admin-bar & {
        padding-top: $headerHeightAdmin;

        @media (orientation: portrait) {
          max-height: calc(56vw + $headerHeightAdmin);
        }
      }

      #rinrose_home-splash_mask-bg {
        background-color: #fff;
        position: fixed;
        background-image: var(--rinrose_home-splash_mask-bg);
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }

      #rinrose_home-splash_mask {
        -webkit-clip-path: $rinroseRpath;
        clip-path: $rinroseRpath;
        margin-left: $rinroseRmargin;
        width: $rinroseRratio;
        max-width: $rinroseRratio;
        margin-right: auto;
        max-height: 92%;

        @media (orientation: portrait) {
          margin-left: auto !important;
          max-width: $rinroseRwidthPortrait !important;
          transform: translateY(-10%);
        }

        .admin-bar & {
          margin-left: $rinroseRmarginAdmin;
          width: $rinroseRratioAdmin;
          max-width: $rinroseRratioAdmin;
        }
      }

      #rinrose_home-splash_mask-logo {
        opacity: 1;
      }

      #rinrose_home-splash_video {
        @media (orientation: portrait) {
          min-height: unset;
          margin-top: 0 !important;
        }
      }

      &.splash-exit {
        #rinrose_home-splash_mask {
          opacity: 0 !important;
        }

        #rinrose_home-splash_mask-logo {
          opacity: 0 !important;
        }

        #rinrose_home-splash_video {
          opacity: 0 !important;
        }
      }
    }
  }
}

#rinrose_home-amenities {
  #rinrose_home-amenities_body-left_image {
    background-position: left top;
    background-size: 60% auto;

    @include media-breakpoint-down(md) {
      background-size: cover;
      background-position: center top;
      background-attachment: scroll;
      height: auto !important;
      min-height: unset !important;
      padding-top: 120vw !important;
    }
  }

  #rinrose_home-amenities_footer {
    margin-top: -20vh;

    @include media-breakpoint-down(md) {
      margin-top: 0;
    }
  }
}

div#rinrose_home-wellness_banner {
  background-color: rgba(0, 0, 0, 0.25);
  background-blend-mode: overlay;
}

@include responsive-parallax("rinrose_home-splash_mask");
@include responsive-parallax("rinrose_home-amenities_banner");
@include responsive-parallax("rinrose_home-amenities_body-left_image");
@include responsive-parallax("rinrose_home-residences_banner");
@include responsive-parallax("rinrose_home-location_banner");
@include responsive-parallax("rinrose_home-wellness_banner");
