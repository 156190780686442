/* *MAP PANEL (HOME & CONTACT)* */
#rinrose_map-holder {
  #map {
    height: 100%;
  }

  .map-legend {
    .legend_icon {
      width: 15px;
      height: 15px;
    }

    .legend_label {
      font-size: 12px;
      text-transform: uppercase;
      font-weight: 600;
      line-height: 1em;
      display: inline-block;
      padding-left: 5px;
      position: relative;
      top: 2px;
    }

    div {
      margin-bottom: 10px;
      margin-top: 10px;
    }

    .legend_label.legend_metroline {
      color: #9eaba3;
    }

    .legend_label.legend_metro {
      color: #9eaba3;
    }

    .legend_label.legend_entertainment {
      color: #02293a;
    }

    .legend_label.legend_shopping {
      color: #b37350;
    }

    .legend_label.legend_recreation {
      color: #6a8171;
    }

    .legend_label.legend_education {
      color: #d8b9ab;
    }

    .legend_label.legend_radius {
      color: #80949c;
    }

    .legend_icon_line.legend_metroline {
      width: 15px;
      height: 2px;
      border-bottom: 2px solid #9eaba3;
      display: inline-block;
      position: relative;
      top: -2px;
    }

    .legend_icon_line.legend_radius {
      width: 15px;
      height: 2px;
      border-bottom: 2px dotted #9eaba3;
      display: inline-block;
      position: relative;
      top: -2px;
    }
  }

  #legend {
    transform: translateX(32px);

    @media (orientation: portrait) {
      display: none !important;
    }
  }

  #legend-portrait {
    .legend_label {
      top: -1px !important;
    }

    div {
      padding: 0.05rem 1rem;
      min-width: 50%;
      text-align: left;
      order: 1;
      margin-bottom: 0;

      &:nth-of-type(1) {
        order: 2;
      }

      &:nth-of-type(2) {
        order: 3;
      }
    }

    @media (orientation: landscape) {
      display: none !important;
    }
  }

  @media (orientation: portrait) {
    height: auto !important;
    min-height: 100vw !important;
    padding-top: 100vw !important;

    #rinrose_map-box {
      max-height: 100vw;
      top: 0;
    }
  }
}
