.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.skip-link {
  position: fixed;
  z-index: 1000;
  top: 0;
  right: 0;
}

.fa-sr-only,
.fa-sr-only-focusable:not(:focus),
.sr-only,
.sr-only-focusable:not(:focus) {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.btn-link {
  font-weight: $btn-font-weight;
}

.panorama {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
}

.vh-full {
  height: calc(100vh - $headerHeight);

  .admin-bar & {
    height: calc(100vh - $headerHeightAdmin);
  }
}

.bg-pos-bottom {
  background-position-y: bottom;
}

.carousel-control-prev {
  width: fit-content;
  padding-right: 5px;
}

.carousel-control-next {
  width: fit-content;
  padding-left: 5px;
}

.mobile-panorama {
  @include media-breakpoint-down(md) {
    height: auto !important;
    background-attachment: scroll !important;

    @media (orientation: landscape) {
      min-height: 100vh !important;
    }

    @media (orientation: portrait) {
      min-height: 56vw !important;
    }
  }
}
