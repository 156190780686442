:root {
  --animate-delay: 250ms !important;

  overflow-x: hidden;
}

section,
.section {
  position: relative;
  //overflow: auto;
}

.wrapper {
  min-height: 100vh;
  background: $white;
  position: relative;
  z-index: 3;
  padding: 0;

  #content {
    padding-top: $headerHeight;
  }

  &::after {
    content: " ";
    width: 100%;
    height: 5px;
    background: $rinrose;
    position: absolute;
    left: 0;
    bottom: -2px;
  }
}

ul {
  ul {
    list-style-type: disc;
  }
}

.rinrose_has_animation {
  &:not(.animate__animated) {
    opacity: 0;
  }
}

#rinrose_banner {
  a {
    color: #fff;
  }

  p {
    margin-bottom: 0;
  }

  .btn-close {
    width: 30px;
    height: 30px;
    background-size: 30px;
    padding: 0 !important;
    right: 1.5rem;
    top: 50%;
    transform: translateY(-50%);
  }
}

@media screen and (max-width: 600px) {
  #wpadminbar {
    position: fixed;
  }
}
