/* *HEADER* */
#wrapper-navbar {
  .admin-bar & {
    top: $adminBarHeight;

    @media screen and (max-width: 782px) {
      top: $mobileAdminBarHeight;
    }
  }

  #rinrose_header {
    min-height: $headerHeight;

    .container-fluid {
      padding-left: 5%;
      padding-right: 5%;
    }

    #rinrose_header-main_brand-logo {
      width: fit-content;
      height: auto;
      display: flex;
      text-align: center;
      padding: 0 !important;
      margin: 0 auto !important;
      max-height: 80px;
      opacity: 1;

      @include media-breakpoint-down(md) {
        max-height: 54px;
      }

      .admin-bar & {
        top: calc($adminBarHeight + 15px);

        @media screen and (max-width: 782px) {
          top: calc($mobileAdminBarHeight + 15px);
        }
      }
    }

    #rinrose_header-offcanvas {
      #rinrose_header-offcanvas_bg {
        background-image: var(--rinrose_header-offcanvas);
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        z-index: -1;
        opacity: 0.25;
      }

      .admin-bar & {
        padding-top: $adminBarHeight;

        @media screen and (max-width: 782px) {
          padding-top: $mobileAdminBarHeight;
        }
      }
    }

    #rinrose_header-offcanvas_header-brand_logo {
      width: fit-content;
      height: auto;
      display: flex;
      text-align: center;
      padding: 0 !important;
      margin: 15px auto !important;
      max-height: 80px;

      @include media-breakpoint-down(md) {
        max-height: 54px;
      }
    }

    ul#rinrose_header-offcanvas_body-nav {
      .menu-item {
        padding: 1rem 2rem;

        a {
          color: $white;
          text-transform: uppercase;
          font-weight: 400;
          font-size: 2.75rem;
          text-decoration: none;
          line-height: 52px;

          @include media-breakpoint-down(md) {
            font-size: 1.8rem;
          }
        }
      }
    }

    #rinrose_header-offcanvas_footer-sep {
      height: 2px;
    }
  }
}
