/* *AMENITIES* */
@include responsive-parallax("rinrose_amenities-splash_banner");
@include responsive-parallax("rinrose_amenities-banner_img");

#rinrose_amenities-tabs {
  #rinrose_amenities-tabs_list {
    min-height: 605px !important;

    .btn-primary {
      border-top: 1px solid transparent;
      border-bottom: 1px solid transparent;

      &.active,
      &:active,
      &:hover {
        background-color: $primary;
        border-top-color: $white;
        border-bottom-color: $white;
      }
    }
  }

  .rinrose_amenities-tabs_content-pane_image {
    object-fit: cover;
    object-position: center;
  }

  .rinrose_amenities-tabs_content-pane_copy {
    --bs-bg-opacity: 0.6;

    background-image: linear-gradient(90deg, var(--bs-primary), rgba(var(--bs-primary-rgb), 0.75), transparent);
  }
}

#rinrose_amenities-carousel {
  .rinrose_amenities-carousel_content-pane_image {
    object-fit: cover;
    object-position: center;
  }

  .rinrose_amenities-carousel_content-bg {
    --bs-bg-opacity: 0.6;

    background-image: linear-gradient(90deg, var(--bs-primary), rgba(var(--bs-primary-rgb), 0.75), transparent);
  }
}

#rinrose_amenities-details {
  .rinrose_amenities-tabs_content-pane_copy,
  .rinrose_amenities-carousel_content-pane_copy {
    ul {
      &:last-child {
        margin-bottom: 0 !important;
      }

      li {
        padding-top: 3px;
        padding-bottom: 3px;
      }
    }
  }
}
