#rinrose_contact-content {
  .wpcf7-form {
    width: 100%;
    max-width: 600px;
    margin: 60px auto 20px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    & > .d-flex {
      width: 100%;

      & > p {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
      }
    }

    & > .mb-4 {
      width: 100%;

      &:nth-child(2),
      &:nth-child(3) {
        width: 48%;
      }
    }

    input,
    textarea {
      max-width: 100%;
      color: $primary;
      box-shadow: none;
      border: 0 solid $primary;
      border-bottom-width: 1px;
      font-weight: 400;
      letter-spacing: 0.05em;
      width: 100%;

      &::placeholder {
        color: $primary;
        font-weight: 600 !important;
      }
    }

    textarea {
      height: 150px;
    }

    .wpcf7-response-output {
      color: $primary;
      border-width: 0;
      text-align: left;
      padding: 0;
    }

    &.sent {
      & > div:not(.wpcf7-response-output) {
        display: none;
      }
    }
  }
}
