// Note: Bootstrap 5 ships with its own default theme colors

$grid-breakpoints: (
  xs: 0,
  sm: 640px,
  md: 896px,
  lg: 1280px,
  xl: 1440px,
  xxl: 1920px
);

$headerHeight: 107px;
$adminBarHeight: 32px;
$mobileAdminBarHeight: 46px;
$headerHeightAdmin: $headerHeight + $adminBarHeight;
$mobileHeaderHeightAdmin: $headerHeight + $mobileAdminBarHeight;
$rinroseRpath: url("#rinrose-clipped_svg");
$rinroseRwidth: 1707;
$rinroseRheight: 1654;
$rinroseRratio: calc(((0.92 * (100vh - $headerHeight)) * $rinroseRwidth) / $rinroseRheight);
$rinroseRratioAdmin: calc(((0.92 * (100vh - $headerHeightAdmin)) * $rinroseRwidth) / $rinroseRheight);
$rinroseRwidthPortrait: calc(0.92 * 56vw * $rinroseRwidth / $rinroseRheight);
$rinroseRheight: calc(0.92 * (100vh - $headerHeight));
$rinroseRheightAdmin: calc(0.92 * (100vh - $headerHeightAdmin));
$rinroseRmargin: calc((100% - $rinroseRratio) / 2);
$rinroseRmarginAdmin: calc((100% - $rinroseRratioAdmin) / 2);

$font-family-base: "Montserrat", sans-serif;
$headings-font-weight: 400;
$font-size-base: 1.125rem;
$font-weight-lighter: lighter;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-lightbold: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$font-weight-bolder: bolder;

$h1-font-size: $font-size-base * 2.5;
$h2-font-size: $font-size-base * 2;
$h3-font-size: $font-size-base * 1.75;
$h4-font-size: $font-size-base * 1.5;
$h5-font-size: $font-size-base * 1.25;
$h6-font-size: $font-size-base;
$small-font-size: $font-size-base * 0.75;
$xsmall-font-size: $font-size-base * 0.5;
$medium-font-size: $font-size-base * 1.15;

$font-sizes: (
  1: $h1-font-size,
  2: $h2-font-size,
  3: $h3-font-size,
  4: $h4-font-size,
  5: $h5-font-size,
  6: $h6-font-size,
  small: $small-font-size,
  xsmall: $xsmall-font-size,
  medium: $medium-font-size,
);

$display-font-sizes: (
  1: 3.9rem,
  2: 3.5rem,
  3: 3rem,
  4: 2.75rem,
  5: 2.5rem,
  6: 2rem
);
$display-font-weight: 400;

$rinrose: #0c2939;
$rinrose_2: #a6bac8;
$primary: $rinrose;
$secondary: $rinrose_2;
$border-radius: 0;
$border-radius-sm: 0;
$border-radius-lg: 0;
$white: #fff;
$light: $white;
$black: #000;

$navbar-dark-toggler-border-color: transparent;
$navbar-light-toggler-border-color: transparent;
$navbar-dark-color: $light;
$navbar-dark-hover-color: rgba($light, 0.55);
$navbar-dark-active-color: rgba($light, 0.55);
$navbar-light-color: $primary;
$navbar-light-hover-color: rgba($primary, 0.7);
$navbar-light-active-color:rgba($primary, 0.55);

$offcanvas-vertical-height: 100vh;

$btn-close-opacity: 1;
$btn-close-hover-opacity: 0.5;
$btn-close-padding-x: 0;
$btn-close-width: 50px;
$btn-close-color: $black;
$btn-close-bg: url("data:image/svg+xml,<svg width='52' height='52' viewBox='0 0 52 52' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M15 37.5L36.5 16M15 16L36.5 37.5M51 26C51 39.8071 39.8071 51 26 51C12.1929 51 1 39.8071 1 26C1 12.1929 12.1929 1 26 1C39.8071 1 51 12.1929 51 26Z' stroke='#{$btn-close-color}' stroke-width='2'/><path d='M15 37.5L36.5 16M15 16L36.5 37.5M51 26C51 39.8071 39.8071 51 26 51C12.1929 51 1 39.8071 1 26C1 12.1929 12.1929 1 26 1C39.8071 1 51 12.1929 51 26Z' stroke='#{$btn-close-color}' stroke-width='2'/></svg>");

$btn-border-width: 1px;

$btn-padding-x: 2em;
$btn-padding-y: 0.875em;
$btn-font-size: $font-size-base * 0.875;

$btn-padding-x-sm: 2em;
$btn-padding-y-sm: 0.875em;
$btn-font-size-sm: $btn-font-size * 0.875;

$btn-padding-x-lg: 3rem;
$btn-padding-y-lg: 1rem;
$btn-font-size-lg: $btn-font-size * 1.5;

$btn-font-weight: 500;
$btn-line-height: 1em;

$utilities: (
  "width": (
    property: width,
    responsive: true,
    class: w,
    values: (
      10: 10%,
      15: 15%,
      20: 20%,
      25: 25%,
      30: 30%,
      35: 35%,
      40: 40%,
      45: 45%,
      50: 50%,
      55: 55%,
      60: 60%,
      65: 65%,
      70: 70%,
      75: 75%,
      80: 80%,
      85: 85%,
      90: 80%,
      95: 95%,
      100: 100%,
      auto: auto,
      content: fit-content,
    )
  ),
  "viewport-height": (
    property: height,
    responsive: true,
    class: vh,
    values: (
      10: 10vh,
      15: 15vh,
      20: 20vh,
      25: 25vh,
      30: 30vh,
      35: 35vh,
      40: 40vh,
      45: 45vh,
      50: 50vh,
      55: 55vh,
      60: 60vh,
      65: 65vh,
      70: 70vh,
      75: 75vh,
      80: 80vh,
      85: 85vh,
      90: 80vh,
      95: 95vh,
      100: 100vh
    )
  ),
  "min-viewport-height": (
    property: min-height,
    responsive: true,
    class: min-vh,
    values: (
      10: 10vh,
      15: 15vh,
      20: 20vh,
      25: 25vh,
      30: 30vh,
      35: 35vh,
      40: 40vh,
      45: 45vh,
      50: 50vh,
      55: 55vh,
      60: 60vh,
      65: 65vh,
      70: 70vh,
      75: 75vh,
      80: 80vh,
      85: 85vh,
      90: 80vh,
      95: 95vh,
      100: 100vh
    )
  ),
  "height": (
    property: height,
    responsive: true,
    class: h,
    values: (
      10: 10%,
      15: 15%,
      20: 20%,
      25: 25%,
      30: 30%,
      35: 35%,
      40: 40%,
      45: 45%,
      50: 50%,
      55: 55%,
      60: 60%,
      65: 65%,
      70: 70%,
      75: 75%,
      80: 80%,
      85: 85%,
      90: 80%,
      95: 95%,
      100: 100%,
      auto: auto,
      content: fit-content,
    )
  ),
  "min-height": (
    property: min-height,
    responsive: true,
    class: min-h,
    values: (
      10: 10%,
      15: 15%,
      20: 20%,
      25: 25%,
      30: 30%,
      35: 35%,
      40: 40%,
      45: 45%,
      50: 50%,
      55: 55%,
      60: 60%,
      65: 65%,
      70: 70%,
      75: 75%,
      80: 80%,
      85: 85%,
      90: 80%,
      95: 95%,
      100: 100%,
    )
  ),
  "font-weight": (
    property: font-weight,
    responsive: true,
    class: fw,
    values: (
      light: $font-weight-light,
      lighter: $font-weight-lighter,
      normal: $font-weight-normal,
      bold: $font-weight-bold,
      semibold: $font-weight-semibold,
      bolder: $font-weight-bolder,
      lightbold: $font-weight-lightbold,
    )
  ),
  "font-size": (
    rfs: true,
    property: font-size,
    responsive: true,
    class: fs,
    values: $font-sizes
  ),
  "opacity": (
    property: opacity,
    values: (
      0: 0,
      25: 0.25,
      50: 0.5,
      75: 0.75,
      100: 1,
    )
  ),
);

@mixin responsive-parallax($id) {
  @include media-breakpoint-only(xs) {
    [id="#{$id}"] {
      background-image: var(--#{$id}_xs, var(--#{$id}));
    }
  }

  @include media-breakpoint-only(sm) {
    [id="#{$id}"] {
      background-image: var(--#{$id}_SD, var(--#{$id}));
    }
  }

  @include media-breakpoint-only(md) {
    [id="#{$id}"] {
      background-image: var(--#{$id}_HD, var(--#{$id}));
    }
  }

  @include media-breakpoint-only(lg) {
    [id="#{$id}"] {
      background-image: var(--#{$id}_FHD, var(--#{$id}));
    }
  }

  @include media-breakpoint-only(xl) {
    [id="#{$id}"] {
      background-image: var(--#{$id}_2K, var(--#{$id}));
    }
  }

  @include media-breakpoint-only(xxl) {
    [id="#{$id}"] {
      background-image: var(--#{$id}_4K, var(--#{$id}));
    }
  }
}
